@font-face {
    font-family: 'Greenwich';
    src: url("assets/fonts/Greenwich_Light.otf");
    font-weight: 100;
}

@font-face {
    font-family: 'Greenwich';
    src: url("assets/fonts/Greenwich-Regular.otf");
    font-weight: 300;
}

@font-face {
    font-family: 'Greenwich';
    src: url("assets/fonts/Greenwich-Medium.otf");
    font-weight: 500;
}

@font-face {
    font-family: 'Greenwich';
    src: url("assets/fonts/Greenwich-SemiBold.otf");
    font-weight: 700;
}

@font-face {
    font-family: 'Greenwich';
    src: url("assets/fonts/Greenwich_Bold.otf");
    font-weight: 900;
}

@font-face {
    font-family: 'Literal';
    src: url("assets/fonts/Literal-Bold.otf");
}
a {
    cursor: pointer;
}
body {
    font-family: 'Greenwich',sans-serif !important;
    background-color: white!important;
    font-size:.9rem !important;
}
#header{
    background:white;
    z-index: 9999;
}
.loader-wrapper {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ff-literal {
    font-family: 'Literal',sans-serif;
}

/* bootstrap resets */
.navbar-toggler{
    border: none !important;
}

/* font weights */

.fw-100 {
    font-weight: 100;
}

.fw-300 {
    font-weight: 300;
}

.fw-500 {
    font-weight: 500;
}

.fw-700 {
    font-weight: 700;
}

.fw-900 {
    font-weight: 900;
}


/* font sizes */

.fs-10 {
    font-size: 10px;
}

.fs-12 {
    font-size: 12px;
}

.fs-14 {
    font-size: 14px;
}

.fs-15 {
    font-size: 15px;
}

.fs-16 {
    font-size: 16px;
}
.fs-17 {
    font-size: 17px;
}

.fs-18 {
    font-size: 18px!important;
}
.fs-20 {
    font-size: 20px;
}
.fs-21 {
    font-size: 21px;
}

.fs-22 {
    font-size: 22px;
}

.fs-26 {
    font-size: 26px;
}

.fs-30 {
    font-size: 30px;
}

.fs-32 {
    font-size: 32px;
}

.fs-36 {
    font-size: 36px;
}

.fs-44 {
    font-size: 44px;
}


/* line heights */

.lh-55 {
    line-height: 55px;
}

.lh-45 {
    line-height: 45px;
}

.lh-42 {
    line-height: 42px;
}

.lh-31 {
    line-height: 31px;
}

.lh-27 {
    line-height: 27px;
}

.lh-25 {
    line-height: 25px;
}

.lh-22 {
    line-height: 22px;
}


.lh-21 {
    line-height: 21px;
}

.lh-20 {
    line-height: 20px;
}
.lh-18 {
    line-height: 18px;
}
@media only screen and (max-width:600px){
    /* line heights */

.lh-55 {
    line-height: 50px;
}

.lh-45 {
    line-height: 38px;
}

.lh-42 {
    line-height: 30px !important;
}

.lh-31 {
    line-height: 29px;
}

.lh-27 {
    line-height: 25px;
}

.lh-25 {
    line-height: 23px;
}

.lh-22 {
    line-height: 20px;
}


.lh-21 {
    line-height: 19px;
}

.lh-20 {
    line-height: 18px;
}
.lh-18 {
    line-height: 16px;
}


/* font sizes */
.fs-10 {
    font-size: 10px;
}

.fs-12 {
    font-size: 11px;
}

.fs-14 {
    font-size: 13px;
}

.fs-15 {
    font-size: 14px;
}

.fs-16 {
    font-size: 15px;
}
.fs-17 {
    font-size: 16px;
}

.fs-18 {
    font-size: 17px!important;
}
.fs-20 {
    font-size: 17px;
}
.fs-21 {
    font-size: 18px;
}

.fs-22 {
    font-size: 20px;
}

.fs-26 {
    font-size: 23px;
}

.fs-30 {
    font-size: 26px;
}

.fs-32 {
    font-size: 28px;
}

.fs-36 {
    font-size: 32px;
}

.fs-44 {
    font-size: 40px;
}
}
@media only screen and (min-width:600px){
.disable-br-mobile br{
    display: none !important;
}
}
@media only screen and (max-width:600px){
    .disable-br-desktop br{
        display: none !important;
    }
    }
/* text colors */

.c-magenta {
    color: #eb1e5f;
}

.c-indigo {
    color: #343288;
}

.c-dark-blue {
    color: #221e54;
}
.c-light-gray{
    color:#b1b0c3;
}
.c-grayish {
    color: #4f4f4f;
}

.c-gray {
    color: #444444;
}

.c-purple {
    color: #44426f;
}

.c-light-blue {
    color: #6fc6ef;
}

.c-darker-blue {
    color: #3dabe0;
}

.c-pale-blue {
    color: #f6fafd;
}
.c-light-grey {
    color:#d5dcdf;
}

.c-lavander {
    color: #e7e6fc;
}

.c-white {
    color:white;
}

.alert-primary {
    color: #343288 !important;
    background-color: #f6fafd !important;
    border-color: #d5dcdf !important;
}

/* background colors */

.bg-magenta {
    background: #eb1e5f;
}

.bg-darker-blue {
    background: #3dabe0;
}

.bg-pale-blue {
    background: #f6fafd;
}


.no-right-border{
    border-right-width: 0 !important;
}
.bg-pale-blue-imp {
    background: #f6fafd !important;
    cursor: pointer;
}

.bg-indigo {
    background: #343288;
}

.bg-dark-blue {
    background: #221e54;
    background: rgb(19,19,37);
    background: linear-gradient(-80deg, rgb(26, 26, 49) 0%, rgba(40,37,93,1) 58%);
}

.bg-white {
    background: white;
}

.bg-light-blue {
    background: #6fc6ef;
}


/* margins */

.mb-40 {
    margin-bottom: 40px;
}

.mb-25 {
    margin-bottom: 25px;
}

.pt-90 {
    padding-top: 90px;
}

.mt-90 {
    margin-top: 90px;
}

.py-90 {
    padding-bottom: 90px;
    padding-top: 90px;
}

.max-width-750 {
    max-width: 750px;
}

.max-width-680 {
    max-width: 680px;
}

.max-width-430 {
    max-width: 430px;
}

.min-width-210 {
    min-width: 210px;
}

.flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.shadow1 {
    box-shadow: 0px 0px 20px rgba(0,0,0,.08);
}

.shadow2 {
    box-shadow: 0px 3px 11px -10px #343288;
}

.zig-zag-heading {
    font-family: 'Literal',sans-serif;
    line-height: 42px;
    font-size: 32px;
    color: #343288;
}

.zig-zag-description,
.carousel-description {
    font-weight: 100;
    line-height: 27px;
    font-size: 17px;
    color: #444444;
}
.zig-zag-description ul {
    list-style-type: none;
}


.menu-wrapper{
    position: sticky;
    top: 0;
    z-index: 9;
    background: white;
}
.menuItem {
     -webkit-transition:all 150ms ease-out;
    -moz-transition:all 150ms ease-out;
    -o-transition:all 150ms ease-out;
    transition:all 150ms ease-out

}
.menuItem{
    color:#4f4f4f;
}
.menuItem:hover{
    color:#3dabe0;
}
.top-menu-wrapper a:hover {
    color: #444444;
}

.top-menu-wrapper a {
    font-size: 12px!important;
}
.accordion-header{
    position:relative;
}
.accordion-header[aria-expanded="true"] .arrow-accordion{
    transform:rotate(0deg);
}
.accordion-header[aria-expanded="false"] .arrow-accordion{
    transform:rotate(180deg);
}

.arrow-accordion{
    position:absolute;
    width:15px;
    height: 15px;
    right:20px;
    top:0px;
    bottom:0px;
    margin:auto;
    background-image:url('./assets/icons/faq-mobile-btn-arrow.svg');
    background-size:15px;
    background-repeat: no-repeat;
    background-position: center;;
}
.how-to-steps-heading {
    font-weight: 700;
    font-size: 17px;
    line-height: 25px;
    /* color: #221e54; */
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
    min-height: 50px;
}
#timeline-wrapper{
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
}
#language-picker.dropdown-toggle::after {
    display: none !important;
}
.timeline-heading {
    font-weight: 700;
    font-size: 22px;
    line-height: 25px;
    /* color: #221e54; */
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
    min-height: 50px;
}

.how-to-steps-description {
    font-weight: 100;
    /* color: #444444; */
    font-size: 14px;
    line-height: 22px;
    text-align: center;
}
.dropdown-menu {
    border: none !important;
    box-shadow: -3px 7px 20px rgb(0 0 0 / 15%);
    border-radius: 10px;
    top: 20px !important;
    left: -80% !important;
}

.dropdown-menu:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: -7px;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid white;
}

/* footer{
  bottom: 0;
  position: absolute;
  width: 100%;
} */

.first-part-footer {
    border-bottom: 1px solid #44426f;
    padding-bottom: 15px;
    margin-bottom: 30px;
}

.first-part-footer h6 {
    font-weight: 700;
    font-size: 17px;
    text-align: left;
    color: #6fc6ef;
    margin-bottom: 15px;
}

.first-part-footer p,
.first-part-footer p a {
    font-weight: 100;
    font-size: 15px;
    text-align: left;
    color: #e7e6fc;
}


/* .second-part-footer .right a:first-of-type h6{
  padding-right: 40px;
} */

.second-part-footer h6 {
    font-weight: 300;
    font-size: 15px;
    color: white;
}

.second-part-footer .left {
    flex: 2;
}

.pink-button {
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    color: white;
    background: #eb1e5f;
    border: 0;
    border-radius: 30px;
    padding: 14px 34px;
    font-size: 21px;
    box-shadow: 3px 3px 14px -6px #eb1e5f;
    display: inline-block;
}

.blue-button {
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    color: white;
    background: #3dabe0;
    border: 0;
    border-radius: 30px;
    padding: 12px 25px;
    font-size: 18px;
    box-shadow: 3px 3px 14px -6px #3dabe0;
}

.dark-blue-button {
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    color: white;
    background: #D3D3D3;
    border: 0;
    border-radius: 30px;
    padding: 14px 60px;
    font-size: 21px;
    box-shadow: 3px 3px 14px -6px #3dabe0;
}
.light-gray-button {
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    color: white;
    background: #e1e0e0;
    border: 0;
    border-radius: 30px;
    padding: 12px 20px;

}
.pink-button,.blue-button,.dark-blue-button{
    -webkit-transition:all 150ms ease-out;
    -moz-transition:all 150ms ease-out;
    -o-transition:all 150ms ease-out;
    transition:all 150ms ease-out
}
.pink-button:hover,.blue-button:hover,.dark-blue-button:hover, .light-gray-button:hover{
   transform:scale(1.02) !important;
   color:white;
   text-decoration: none;
}

.steps-number-circle {
    background: #221e54;
    border-radius: 50%;
    color: white;
    font-size: 16px;
    font-weight: 900;
    width: 30px;
    height: 30px;
    margin: 0 auto;
    padding-top: 3px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.timeline-circle {
    background: white;
    border-radius: 50%;
    color: white;
    font-size: 16px;
    font-weight: 900;
    width: 15px;
    height: 15px;
    margin: 0 auto;
    padding-top: 3px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.single-step-wrapper:not(:last-of-type) .steps-number-circle:after, .single-step-wrapper:not(:last-of-type) .timeline-circle::after {
    content: "";
    border-top: 2px solid #6fc6ef;
    width: 160px;
    top: 45px;
    position: absolute;
    left: 205px;
}

.single-step-wrapper:not(:last-of-type) .timeline-circle::after{
    border-top: 1px solid white;
}
.single-step-wrapper:last-of-type .steps-number-circle::before{
    content: "";
    position: absolute;
    left: 61px;
    top: 36px;
    display: block;
    border: solid #6fc6ef;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 8px;
    transform: rotate(-45deg);
}



.slick-prev:before,
.slick-next:before {
    color: #6fc6ef!important;
    font-size: 40px!important;
    opacity: 1!important;
}

.slick-prev,
.slick-next {
    width: unset!important;
    height: unset!important;
}

.slick-dots li button:before {
    font-size: 12px!important;
    color: #6fc6ef!important;
    opacity: 1!important;
}

.slick-dots li.slick-active button:before {
    color: #eb1e5f!important;
    opacity: 1!important;
}

.slick-dots li {
    margin: 0!important;
}

.slick-active>div,
.slick-active>div>div,
.carousel-img-wrapper,
img,
.carousel-right-wrapper,
.carousel-right,
.carousel-name-position,
.carousel-heading,
.carousel-description {
    outline: 0!important;
}

.swiper-pagination-bullet {
    background-color: #6fc6ef!important;
    opacity: 1!important;
}

.swiper-pagination-bullet-active {
    background-color: #eb1e5f!important;
    opacity: 1!important;
}

.h-410 {
    height: 410px;
}

.carousel-name-position {
    font-weight: 500;
    font-size: 17px;
    color: #343288;
    text-align: left;
}

.carousel-heading {
    font-weight: 900;
    font-size: 36px;
    line-height: 40px;
    color: #221e54;
}

.carousel-img-wrapper {
    position: absolute;
    right: 40px;
    left: 40px;
    z-index: 10;
}

.carousel-right-wrapper {
    background-color: #f6fafd;
    border-radius: 20px;
    max-width: calc(100% - 260px);
    position: absolute;
    right: 40px;
    /* height: 405px; */
}

.carousel-right {
    padding: 20px 50px 40px 90px;
}

.robot-wizard-flow {
    position: absolute;
    bottom: -70px;
}

.wizard-flow-wrapper img {
    width: 505px;
}

#carousel-sc-2 .slick-prev:before,
#carousel-sc-2 .slick-next:before {
    background-image: url("./assets/images/carousel-arrow-left.svg");
    opacity: 1;
    background-size: 50px 50px;
    display: inline-block;
    width: 50px;
    height: 50px;
    content: "";
    background-repeat: no-repeat;
}

#carousel-sc-2 .slick-next {
    transform: rotate(180deg);
    transform-origin: 51% 26%;
}

#carousel-logos .slick-prev:before,
#carousel-logos .slick-next:before {
    background-image: url("./assets/icons/logos-arrow.svg");
    opacity: 1;
    background-size: 30px 30px;
    display: inline-block;
    width: 30px;
    height: 30px;
    content: "";
    background-repeat: no-repeat;
}

#carousel-logos .slick-prev {
    transform: rotate(90deg);
    transform-origin: 51% 26%;
}

#carousel-logos .slick-next {
    transform: rotate(270deg);
    transform-origin: 51% 26%;
}

.footer-links {
    padding-left: 30px;
}

.zig-zag-wrapper {
    width: 100%;
    padding-top: 60px;
    margin: 0 auto!important;
}

.zig-zag-wrapper,
.zig-zag-wrapper .text,
.benefit-img {
    padding-left: 30px;
    padding-right: 30px;
}

.main-hero-image {
    padding-top: 40px;
    width: 75%;
    display: flex;
    margin: 0 auto;
}

.footer-social-row {
    margin-top: 50px;
}

.footer-social-row a:not(:last-of-type) {
    margin-right: 15px;
}

.footer-logo-social {
    padding: 0!important;
}

#carousel-sc-2 {
    padding-top: 30px;
    padding-bottom: 30px;
}

.wizard-wrapper {
    background: white;
    /* margin: 0 35px; */
}

.form-nav-wrapper {
    margin: 0 35px;
}


/* Text Input */

.form-label {
    color: #343288;
    font-weight: 500!important;
    margin: 0px 0px 5px;
    font-size: 17px;
}

.form-control {
    border-radius: 8px!important;
    color: '#221e54'!important;
    font-weight: 300!important;
    padding: 23px 20px!important;
    /* margin-bottom: 30px!important; */
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-radius: 0px !important;
}
.react-tel-input .form-control{
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    font-size: 16px !important;
    font-weight: 400 !important;
}

.input-group {
    margin-bottom: 30px!important;
}

.form-control::placeholder {
    color: #444444 !important;
    font-weight: 300 !important;
}


/* form-control-domain */

.form-control-domain {
    border-width: 0px!important;
    border-bottom-width: 1px!important;
    border-radius: 0px!important;
    /* color: '#221e54'!important;
  font-weight: 300!important;
  padding: 6px 20px!important;
  margin-bottom: 30px!important; */
}


/* Stepper */

.MuiStepper-root {
    background: transparent!important;
    padding: 24px 0px !important;
}

.MuiStepConnector-line {
    margin: auto -8px!important;
    border-color: '#3dabe0'!important;
}

.form-control.is-invalid {
    background-image: none!important;
}

.pricing-table-category-row {
    background-color: #F6FAFD;
    cursor: pointer;
}

.pricing-table-arrow {
    transform: rotate(180deg);
    transition: transform 1s linear;
    margin: 3px 5px 0 0;
    float: right;
}
.pricing-table-arrow.open {
    transform: rotate(0deg);
    transition: transform 1s linear;
}
/* Dropdown */


/* .dropdown-custom{
  border: 1px solid '#444444' !important;
} */

#dropdownMenuButton {
    background-color: white!important;
    border-radius: 0px!important;
    color: #221e54!important;
    font-weight: 400!important;
    border-width: 1px!important;
    padding: 10px 20px!important;
    text-align: left;
    border-color: #c2c1db;
    border-style: solid;
    border-top:none;
    border-left:none;
    border-right:none;
    font-size:16px;
}
#custom.dropdown-menu.show {
    border: none;
    box-shadow: -1px 11px 30px rgba(0,0,0,.08);
    border-top: 1px solid #6fc6ef;
    margin-top: -1px;
    border-radius: 0px;
}
.dropdown-toggle::after {
    position: absolute;
    top: 18px;
    right: 20px;
    font-size: 23px;
    color: #c2c1db;
}


.postfix-domain-dropdown .dropdown-toggle::after {
    /* right:65px!important; */
    right: 15px!important;
}

.selected-flag {
    background-color: white;
    border-radius: 7px 0px 0px 7px !important;
}

.flag-dropdown {
    border-radius: 7px 0px 0px 7px !important;
}


/* .dropdown-toggle placeh:active {
  color: '#221e54'!important;
} */


/* #dropdownMenuButton:active{
  color: '#221e54'!important;
  background-color: #eb1e5f!important;
} */

.vertical-alignment-middle {
    top: 50%;
    transform: translateY(-50%);
}

.middle-menu-wrapper {

    flex-grow: 1;
}

.middle-menu-wrapper a:hover {
    text-decoration: none;
}

.dropdown-text {
    color: '#221e54'!important;
    font-weight: 100!important;
}

.custom-label {
    color: #343288!important;
    font-weight: 900!important;
}


/* Checkbox label */

.form-check-label {
    color: #444444;
    margin: 0px 10px;
}


/* Radio button checkbox */

.form-check-input {
    color: #343288;
}


/* Radio button checkbox in Wizard/Plans */

.plans-check>label {
    color: #eb1e5f;
}

.was-validated .form-check-input:valid~.form-check-label,
.form-check-input.is-valid~.form-check-label {
    color: #eb1e5f!important;
}


/* Total */

.total-price {
    border-radius: 25px;
    background: #f6fafd;
    color: #444444;
    font-weight: 100;
    padding-left: 30px;
    padding-top: 30px;
    padding-right: 30px;
    padding-bottom: 5px;
}


/* hr */

.custom-hr {
    margin-top: 0.7rem!important;
    margin-bottom: 0.7rem!important;
    background-color: #C9EAF9!important;
}

/*.MuiSvgIcon-root {*/
/*    color: #35A9E1;*/
/*}*/

/* MuiSvgIcon-root MuiStepIcon-root MuiStepIcon-active */


/* .MuiStepIcon-active{
  color: #343287!important;
}


.MuiStepIcon-text{
  color: red!important;
} */


/* .react-tel-input > .form-control{
  margin-left: 25px!important;
  width: auto!important;
} */

input[type="tel"].form-control {
    margin-left: 25px!important;
    width: inherit!important;
}

.form-control:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: none!important;
    border-color: #3dabe0!important;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
    box-shadow: none!important;
}

.dropdown-item:hover,
.dropdown-item:focus {
    background-color: #f6fafd!important;
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: #f6fafd!important;
    color: #444444!important;
}

.dizajn-btns-wrapper {
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.MuiStepIcon-root.MuiStepIcon-completed,
.MuiStepIcon-root.MuiStepIcon-active {
    color: #343288!important;
}

.MuiStepIcon-text {
    fill: #3dabe0!important;
}

.MuiStepIcon-active .MuiStepIcon-text {
    fill: white!important;
}

.MuiStepIcon-root {
    color: #f6fafd!important;
    border: 1px solid #c9eaf9;
    border-radius: 50%;
}

.MuiStepIcon-active,
.MuiStepIcon-completed {
    border: 0;
}

.MuiStepConnector-completed .MuiStepConnector-line,
.MuiStepConnector-active .MuiStepConnector-line {
    border-color: #343288!important;
}

.MuiStepConnector-line {
    /* border-color: #3dabe0!important; */
    border-color: #C9EAF9!important;
}

.MuiTypography-body2 {
    font-weight: 100!important;
    font-family: inherit!important;
}

.form-check-label {
    color: #343288;
    font-weight: 500!important;
    font-family: inherit!important;
    font-size: '5rem' !important;
}

.MuiStepLabel-label {
    color: #444444!important;
}

.MuiStepLabel-active {
    font-weight: 900!important;
    color: #343288!important;
}

.MuiInputBase-input::placeholder {
    color: #b2b0c4!important;
    font-weight: 100!important;
}

.MuiInputBase-input {
    color: #221e54!important;
    font-weight: 100!important;
    font-family: 'Greenwich',sans-serif !important;
    padding-left: 10px!important;
    font-size: 14px!important;
}

.MuiInput-underline:after {
    border-bottom: 1px solid #c2c1db!important;
}

/*.MuiInput-underline:hover:not(.Mui-disabled):before {*/
/*    border-bottom: 0!important;*/
/*}*/

.MuiInput-underline .MuiInput-underline:after {
    border-bottom: 1px solid #3dabe0!important;
}

.logo-name-mobile {
    display: none;
}

.MuiStepLabel-labelContainer,
.MuiStepLabel-iconContainer {
    cursor: pointer;
}

.MuiInput-underline.Mui-disabled:before {
    border-bottom-style: solid!important;
    border-bottom-color: #c2c1db;
}

.payment-icon {
    width: 25px;
    right: 0;
    position: absolute;
    bottom: 0;
}

.payment-label-radio-wrapper {
    display: flex;
    height: 20px;
    align-items: center;
}

.form-check-input {
    width: 16px!important;
    height: 16px!important;
    top: -2.3px!important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


/* Firefox */

input[type=number] {
    -moz-appearance: textfield;
}

.robot-top-left {
    left: -75px;
    top: 30px;
}

.PhoneInputCountryIcon {
    width: 30px;
    float: left;
}

.odberiDesignWrapper {
    padding: 30px 120px!important;
}

.dizajn-btns-wrapper-mobile {
    display: none!important;
}

.card-title {
    margin: 0!important;
}

.meet-boro-lottie {
    width: 100% !important;
    position: absolute;
}

.zig-zag-checkmarked-list li {
    position: relative;
    padding-bottom: 10px;
}

.zig-zag-checkmarked-list li::before {
    content: url("./assets/icons/check.png");
    font-size: 10px!important;
    width: 20px!important;
    height: 10px!important;
    background-size: 10px 10px!important;
    display: inline-block;
    margin-right: 10px;
    margin-top: 10px;
    transform: scale(.3);
    position: absolute;
    left: -40px;
    top: -9px;
    background-position: right bottom;
}

.zig-zag-checkmarked-list li::marker {
    content: none!important;
}

.meet-boro-row-wrapper {
    height: 500px;
}

.odberi-btn-mobile {
    display: none;
}

.modal-description {
    max-width: 88%;
}

.customer-service-contact-us-heading {
    position: relative;
    margin-top: 40px;
}

.customer-service-contact-us-heading::after {
    content: " ";
    border-bottom: 1px solid #eb1e5f;
    width: 50%;
    display: table-cell;
    position: absolute;
    padding-top: 5px;
}

#faq-accordion .card-header {
    background: transparent;
    border-radius: 0px!important;
    border:none;
    border-bottom: 1px solid #221c55;
}

#faq-accordion {
    margin-bottom: 20px;
}

.insightsWrapper {
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(246,250,253,1) 58%);
    padding: 20px 0;
}

.single-question:focus,
.single-question:focus-visible,
.card-header button {
    border: 0!important;
    box-shadow: none!important;
}

#faq-accordion .card-header button:hover,
#faq-accordion .card-header button:focus {
    text-decoration: none!important;
}

#faq-accordion .card {
    border-radius: 0px!important;
    border-top:none;
    border-left:none;
    border-right:none;
}

.categories-btn-mobile {
    margin-bottom: 30px;
    text-align: center;
}

.categories-btn-mobile .dropdown button::after {
    /* content:none!important;  */
    border: none!important;
    background: url("./assets/icons/faq-mobile-btn-arrow.svg");
    opacity: 1;
    background-size: 20px 20px;
    display: inline-block;
    width: 20px;
    height: 20px;
    content: "";
    background-repeat: no-repeat;
    position: absolute;
    right: 15px;
    top: 15px;
}

.categories-btn-mobile .dropdown button {
    color: #342f89;
    background: #f6fafd;
    border-color: #342f89;
    padding: 13px;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 900;
    width: 360px;
    position: relative;
    padding-right: 28px;
    text-align: left;
    border-radius: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.categories-btn-mobile .btn-success:not(:disabled):not(.disabled):active,
.show>.btn-success.dropdown-toggle {
    background-color: transparent!important;
    border-color: #342f89!important;
    color: #342f89!important;
    box-shadow: none!important;
}

.categories-btn-mobile .btn-success:focus {
    box-shadow: none!important;
}

.active-question-category {
    color: #eb1e5f;
    background: #f6fafd;
    margin-bottom: 0;
    padding: 10px;
    width: 100%;
    border-radius: 4px;
    cursor: pointer;
    font-size: 15px;
    font-weight: 900;
}

.question-category {
    color: #444444;
    margin-bottom: 0;
    padding: 10px;
    width: fit-content;
    border-radius: 10px;
    cursor: pointer;
    font-size: 15px;
    font-weight: 300;
}

.question-category:hover {
    color: #eb1e5f;
}

.question-category-heading {
    font-size: 26px;
    font-weight: 900;
    color: #221e54;
    padding-bottom: 20px;
}

.question-category-heading::after {
    content: " ";
    border-bottom: 1px solid #eb1e5f;
    width: 25%;
    display: table-cell;
    position: absolute;
    padding-top: 10px;
}

input[type="tel"].form-control {
    margin-left: 0!important;
    padding-left: 45px!important;
}

.flag-dropdown {
    border-top-width: 0!important;
    border-bottom-width: 0!important;
    border-left-width: 0!important;
}

.logos-container {
    padding-top: 20px;
    padding-bottom: 20px;
}
.homepage-single-pdf{
    cursor:pointer;
}
.homepage-single-pdf:hover {
    opacity: 1;
}

.pdf-overlay::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    /* transition: 0.5s ease; */
}

/*.MuiInput-underline:before {*/
/*    border-bottom-color: #ced4da!important;*/
/*}*/

.registration-input>div>input::placeholder {
    font-weight: 100!important;
    color: #221c55!important;
    font-size: 17px!important;
}

.header-button {
    background: white;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    color: #3dabe0;
    border: 1px solid #3dabe0;
    border-radius: 30px;
    padding: 7px 30px;
    font-size: 14px;

    -webkit-transition:all 250ms ease-out;
    -moz-transition:all 250ms ease-out;
    -o-transition:all 250ms ease-out;
    transition:all 250ms ease-out
}
.header-button:hover{
    background:#3dabe0;
    color:white;
}


@media screen and (max-width: 1200px) {
    .modal-xl {
        max-width: unset!important;
        padding: 40px!important;
    }
    .zig-zag-wrapper .benefit-img img {
        width: 100%!important;
        height: 100%!important;
    }
    .carousel-img-wrapper {
        top: 0;
        display: flex;
        justify-content: center;
    }
    .single-step-wrapper:not(:last-of-type) .steps-number-circle:after, .single-step-wrapper:not(:last-of-type) .timeline-circle::after {
        width: 120px;
        left: 180px;
    }
    .single-step-wrapper:last-of-type .steps-number-circle::before, .single-step-wrapper:last-of-type .timeline-circle::before {
        left: 40px;
    }
    .carousel-right-wrapper {
        position: relative;
        right: auto;
        margin-top: 70px;
    }
    .testimonials-carousel-wrapper {
        height: auto;
    }
    .carousel-right {
        padding: 35px;
        padding-top: 200px;
    }
    .zig-zag-wrapper .benefit-img img {
        width: 435px!important
    }
}

@media screen and (max-width: 1100px) {
    .lottie-wizzard-wrapper {
        display: none;
    }
}

@media screen and (max-width: 991px) {
    .modal-description {
        max-width: 100%;
    }
    .modal-wrapper {
        height: auto!important;
    }
    .modal-cart-img-wrapper {
        border-top-left-radius: 0!important;
        border-bottom-left-radius: 0!important;
        padding-bottom: 20px!important;
        height: auto!important;
    }
    .modal-cart-text-wrapper {
        height: auto!important;
        padding-bottom: 50px!important;
    }
    .odberi-btn-desktop {
        display: none;
    }
    .odberi-btn-mobile {
        display: block;
        bottom: 10px!important;
        right: 60px;
        position: absolute!important;
    }
    .modal-cart-img-wrapper {
        order: 2;
    }
    .modal-cart-text-wrapper {
        order: 1;
        border-bottom-left-radius: 0!important;
        border-bottom-right-radius: 0!important;
    }
    .odberiDesignWrapper {
        padding: 30px 70px!important;
    }
    .steps-number-circle::after,
    .steps-number-circle::before, .timeline-circle::before, .timeline-circle::after {
        content: none!important;
    }
    .single-step-wrapper {
        margin-top: 30px;
    }
    .zig-zag-wrapper,
    .zig-zag-wrapper .text,
    .benefit-img {
        padding-left: 0;
        padding-right: 0;
    }
    #carousel-sc-2 {
        padding-bottom: 20px;
    }
    .zig-zag-wrapper .benefit-img img {
        width: 315px!important;
        height: 269px!important;
    }
    .testimonials-carousel-wrapper {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media screen and (max-width: 991px) and (min-width: 768px) {
    .zig-zag-wrapper .text,
    .zig-zag-wrapper .benefit-img {
        padding-left: 15px!important;
        padding-right: 15px!important;
    }
    .benefit-img {
        height: 281px!important;
    }
}

@media screen and (max-width: 870px) {
    .meet-boro-lottie {
        width: unset!important;
    }
}

@media screen and (max-width: 800px) {
    .form-generator-wrapper {
        padding: 30px 60px!important;
    }
    .MuiTypography-body2 {
        font-size: 12px!important;
    }
}

@media screen and (max-width: 767px) {

    .vertical-alignment-middle {
        top: 40px;
    }
    .middle-menu-wrapper {
        align-items: flex-end;
        bottom: 10px;
    }
    .registration-square-wrapper {
        padding: 15px!important
    }
    .logo-name-desktop {
        margin-top: 10px;
    }
    .dizajn-btns-wrapper-desktop {
        display: none!important;
    }
    .dizajn-btns-wrapper-mobile {
        display: block !important;
        /* text-align: center; */
        padding-bottom: 20px;
        justify-content: center;
        align-self: center;
    }
    .dizajn-btns-wrapper-mobile button {
        font-size: 18px!important;
        padding: 5px 24px;
        display: block;
    }
    .footer-links {
        padding: 0!important;
    }
    .footer-logo-social {
        order: 2;
        display: flex;
        margin: 30px 0 10px;
    }
    .footer-logo-wrapper {
        flex: 2;
    }
    .footer-social-row {
        align-items: center;
        flex: 1;
        margin-top: 0;
        width: fit-content;
    }
    .second-part-footer {
        flex-direction: column;
    }
    .second-part-footer .left {
        order: 2;
        margin-top: 10px;
    }
    .second-part-footer h6 {
        text-align: center;
    }
    .second-part-footer .right {
        margin: 0 auto;
    }
}

@media screen and (max-width: 768px) {
    .card-title {
        text-align: center!important;
    }
    .zig-zag-wrapper .benefit-img img {
        display: flex;
        /* width: 474px!important;  */
        margin: 0 auto;
        width: 100%!important;
        height: 100%!important;
    }
    .fs-44 {
        font-size: 34px;
    }
    .fs-19 {
        font-size: 19px;
    }
    .fs-21 {
        font-size: 17px;
    }
    .fs-23 {
        font-size: 22px;
    }
    .fs-13 {
        font-size: 13px;
    }
    .fs-12 {
        font-size: 20px;
    }
    .lh-55 {
        line-height: 40px;
    }
    .mb-40 {
        margin-bottom: 30px;
    }
    .lh-31 {
        line-height: 27px;
    }
    .zig-zag-heading {
        font-size: 26px;
        line-height: 36px;
    }
    .zig-zag-description {
        font-size: 16px;
        line-height: 25px;
    }
    .fs-36 {
        font-size: 28px;
    }
    .fs-30 {
        font-size: 26px!important;
    }
    .lh-45 {
        line-height: 35px;
    }
    .py-90 {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .mt-90 {
        margin-top: 70px;
    }
    .meet-boro-lottie {
        width: 180px!important;
    }
    .meet-boro-row-wrapper {
        height: 300px;
    }
    .robot-wizard-flow {
        width: 415px;
    }
    .shaped-white-bg {
        object-fit: cover;
    }
    .testimonial-img {
        width: 135px;
        height: 152px;
    }
    .carousel-name-position {
        font-size: 18px;
    }
    .carousel-heading {
        font-size: 28px;
        line-height: 33px;
    }
    .carousel-description {
        font-size: 17px;
        line-height: 26px;
    }
    .carousel-right-wrapper {
        position: relative;
        right: auto;
        margin-top: 35px;
        max-width:initial;
    }
    .testimonials-carousel-wrapper {
        height: auto;
    }
    .carousel-right {
        padding: 25px;
        padding-top: 120px;
        padding-bottom:40px;
    }
    .carousel-testimonials-logo {
        margin: 0 auto;
    }
    .benefit-img {
        order: 2;
    }
    .wizard-flow-wrapper {
        width: 100%;
    }
    .wizard-flow-wrapper img {
        width: 415px;
    }
    .pink-button {
        font-size: 20px;
    }
}

@media screen and (max-width: 575px) {
    .carousel-right{
        padding-top:280px;
    }
    .single-step-wrapper{
        display: flex;
        align-items: flex-start;
    }
    .single-step-wrapper img {
        width: 70px;
    }
    .logos-container {
        padding-bottom: 75px;
    }
    .min-width-210 {
        min-width: 50%;
    }
    .steps-icon {
        float: left;
    }
    .steps-text-wrapper {
        float: left;
        padding-left: 20px;
    }
    .total-price {
        margin-left: -25px;
        margin-right: -25px;
    }
    .form-nav-wrapper {
        margin: 0;
    }
    .container {
        padding: 0 20px!important;
    }
    .custom-label {
        /* padding-left: 5px!important;
        padding-right: 5px!important; */
    }
    .meet-boro-lottie {
        width: 255px!important;
    }
    .how-to-steps-description {
        text-align: left;
    }
    .meet-boro-lottie {
        position: relative;
    }
    .meet-boro-row-wrapper {
        height: unset;
    }
    .testimonial-img {
        width: 140px;
        height: auto;
    }
    #carousel-sc-2>div {
        padding: 0!important;
    }
    .carousel-name-position {
        line-height: 20px;
    }
    .carousel-testimonials-logo {
        margin: 0;
    }
    .main-hero-image {
        width: 100%;
    }
    .first-section-wrapper {
        padding-left: 30px;
        padding-right: 30px;
    }
    .footer-col {
        margin-bottom: 30px;
    }
    .meet-boro-wrapper {
        padding-top: 15px!important;
    }
    .meet-boro-text-wrapper {
        order: 2;
    }
    .robot-wrap {
        order: 1;
        justify-content: center;
        display: flex;
    }
    .how-to-steps-heading {
        padding-top: 3px;
        text-align: left;
        margin-bottom: 0;
        padding-left: 40px;
    }
    .steps-number-circle, .timeline-circle {
        margin: 0!important;
        float: left;
        position: absolute;

    }
    .timeline-circle{
      display: none;;
    }
    .timeline-heading{
       padding:0px;
       min-height: auto;
       margin-bottom: 0px;
    }
    .steps-number-heading-wrap {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        align-items:flex-start;
    }
    #timeline-wrapper{
        background-attachment: initial;
        background-position: center;;
    }
    #timeline-wrapper  .how-to-steps-heading{
        padding:0px;
    }
    .logo-name-mobile {
        display: block;
        padding-left: 0px;
        padding-top: 0px;
    }
    .carousel-testimonials-logo{
        width:130px;
    }
    .logo-name-desktop {
        display: none;
    }
    .carousel-img-wrapper {
        flex-direction: column;;
    }
    #carousel-sc-2 .slick-arrow,
    #carousel-sc-2 .slick-next {
        display: none!important;
    }
    #carousel-sc-2 {
        overflow: hidden;
    }
    .how-to-steps-heading {
        min-height: unset;
    }
    .footer-logo-social {
        padding-left: 15px!important;
        padding-right: 15px!important;
    }
    .robot-wrap {
        text-align: center;
        padding-top: 25px;
    }
    .meet-boro-lottie {
        width: 142px;
    }
    .wizard-flow-wrapper img {
        width: 320px;
    }
}

@media screen and (max-width: 425px) {
    .fs-44 {
        font-size: 30px;
    }
    .fs-21 {
        font-size: 17px;
    }
    .lh-31 {
        line-height: 26px;
    }
    .zig-zag-heading {
        font-size: 20px;
        line-height: 25px;
    }
    .mb-40 {
        margin-bottom: 20px;
    }
    .py-90 {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .carousel-name-position {
        line-height: 22px;
    }
    .carousel-heading {
        font-size: 20px;
        line-height: 25px;
    }
    .carousel-description {
        font-size: 16px;
        line-height: 25px;
    }
    .slick-dots {
        bottom: 0px!important;
    }
    .pink-button {
        font-size: 18px;
        padding: 11px 28px;
    }
    /* .carousel-right{
    text-align: center;
  } */
    .footer-logo-social {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .footer-logo-wrapper {
        margin-bottom: 30px;
    }
    .second-part-footer .right {
        display: flex;
        flex-direction: column;
    }
    .second-part-footer .right a:first-of-type h6 {
        padding-right: 0;
    }
    .second-part-footer .left {
        padding-left: 15px;
        padding-right: 15px;
    }
    .fs-21 {
        font-size: 16px;
        line-height: 25px;
    }
    .wizard-flow-wrapper img {
        width: calc(100% - 20px);
    }
    .fs-36 {
        font-size: 25px;
    }
    .categories-btn-mobile .dropdown button {
        width: 100%;
    }
}

.timeline-item, .timeline-item-title{
    color:white !important;
  }


  .rs-table-cell-header:not(:first-child) > .rs-table-cell {
    border-top: 1px solid rgb(242, 242, 245) !important;
    border-top-width: 1px !important;
  }

  .rs-table-body-wheel-area .rs-table-cell.rs-table-cell-first{
    border-left: 1px solid rgb(242, 242, 245) !important;
    border-left-width: 1px !important;
  }

  .rs-table-body-wheel-area .rs-table-cell:nth-child(4){
    border-right: 1px solid rgb(242, 242, 245) !important;
    border-right-width: 1px !important;
  }

  @media only screen and (min-width:1900px){
    .big-container{
            max-width:1540px !important;
    }
    }

  @media only screen and (min-width:1600px) and (max-width:1900px){
    .big-container{
            max-width:1540px !important;
    }
    }
@media only screen and (min-width:1500px) and (max-width:1600px){
.big-container{
        max-width:1440px !important;
}
}
@media only screen and (min-width:1400px) and (max-width:1500px){
  .big-container{
        max-width:1340px !important;
  }
}
@media only screen and (min-width:1300px) and (max-width:1400px){
    .big-container{
          max-width:1240px !important;
    }
  }

  @media only screen and (min-width: 1200px){
  .container{
      max-width: 1240px !important;
  }
}





#about-vendor-wrapper{
    min-height: 80vh;
    background-image:url('../src/assets/images/v-shape.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.shape-blue-vertical{
    position:absolute;
    left: 0px;
    top: 30vh;
    width: 100px;
}
.shape-pink-vertical{
    position:absolute;
    right: 0px;
    top: 15vh;
    width: 100px;
}


@media only screen and (max-width:600px) {

 
    .shape-blue-vertical{
       display: none;
    }
    .shape-pink-vertical{
       display: none;
    }
    #about-vendor-wrapper{
        min-height: auto;
    }
    .vendor-main-desc{
        font-size:35px;
    }
    .about-section-tagline{
        font-size:1.4rem;
    }
}
.plan-wrapper {
    box-shadow: 0px 0px 30px rgb(0 0 0 / 10%);
    padding: 20px;
    margin: 20px 0;
}

h3.plan-name {
    color: #3dabe0;
}

.custom-control-input:checked ~ .custom-control-label::before {
    border-color: #3dabe0 !important;
    background-color: #3dabe0 !important;
}
.custom-control-input:disabled~.custom-control-label::before {
    border-color: #e9ecef !important;
    background-color: #e9ecef !important;
}

.plan-name {
    color: #3dabe0;
    text-align: center;
    font-family: "Literal",sans-serif;
    border-bottom: 1px solid #f2f2f5;
    padding-bottom: 5px;
}

.plan-price {
    font-family: 'Literal',sans-serif;
    color: #343288;
    text-align: center;
    margin-top: 10px;
}

.cta-arrow {
    position: absolute;
    width: 60px;
    right: -20px;
}


 .fees-table th,
 .fees-table td{
     padding:6px;
     text-align: center;
 }
 .fees-table .table-row-header {
    border-top:1px solid #e9ecef;
 }
.main-table.table {
        border-collapse: collapse;
        border: 0;
        width:100%;
      }
     
      .main-table     th,
      td {
        border: 1px solid #e9ecef;
        background-clip: padding-box;
        scroll-snap-align: start;
      }
      
    
      .main-table  tr:first-child th,
      .main-table   tr:first-child td {
        border-top: 0 !important;
      }
      .main-table    {
        z-index: 1000;
        position: relative;
      }
      .main-table    th,
      .main-table    td {
        padding: 0.6rem;
        min-width: 6rem;
        margin: 0;
        color:#4f4f4f;
        font-weight: 300;
      }
      .main-table     th {
        position: sticky;
        top: 0;
        border-top: 0;
        background-clip: padding-box;
        text-align: left;
      }
      .main-table td {
          text-align: center;
      }
      .main-table     th.pin {
        left: 0;
        z-index: 1001;
        border-left: 0;
      }
      .main-table     th {
        background-clip: padding-box;
      }
      .main-table      {
        z-index: 10;
        position: relative;
      }
      .main-table      th {
        position: sticky;
        left: 0;
      }
   
      .table-row-header{
        border-left: 1px solid #e9ecef;
        border-right: 1px solid #e9ecef;
        border-bottom: 1px solid #e9ecef;
        border-color:#e9ecef !important;
        background:#F6FAFD;
      }
    .th-borderless th{
        border:none !important;
    }
      .select-payment-interval-wrapper{
        padding:10px;
        display: flex;
        justify-content: center;
      }
      .select-payment-interval-wrapper .form-check{
          display: flex !important;
          flex-direction: column-reverse;
          margin: 5px;
          padding: 10px;
      }
      .select-payment-interval-wrapper div:first-child .form-check {
          border-right: 1px solid #e9ecef;
      }
   
      .select-payment-interval-wrapper .form-check-input {
        bottom: 0;
        margin: auto;
        left: 5px;
        position: relative;
        margin-top:10px;
        display: none;
      }
      .select-payment-interval-wrapper .form-check-input:checked + label {
          color:white;
          background:#3dabe0;

      }
.select-payment-interval-wrapper label {
    padding:12px 40px;
    border-radius: 20px;
    background:#f6fafd;
    text-align: center;
}
.discount-text{
    color:#eb1e5f;
    background:white;
    font-size:0.65rem;
    padding:3px 10px;
    position:relative;
    top:-25px;
    border-radius:3px;
    box-shadow: 0px 0px 6px rgb(0, 0, 0, 0.12);
}

.main-table-plans-wrapper.table {
    border-collapse: collapse;
    border: 0;
    width:100%;
  }
 
  .main-table-plans-wrapper     th,
  td {
    border: 1px solid #e9ecef;
    background-clip: padding-box;
    scroll-snap-align: start;
  }
  

  
  .main-table-plans-wrapper    {
    z-index: 1000;
    position: relative;
  }
  .main-table-plans-wrapper    th,
  .main-table-plans-wrapper    td {
    padding: 0.6rem;
    min-width: 6rem;
    margin: 0;
    
  }
  .main-table-plans-wrapper     th {
    position: sticky;
    top: 0;
    background-clip: padding-box;
    text-align: left;
  }
  .main-table-plans-wrapper td {
      text-align: center;
  }
  .main-table-plans-wrapper     th.pin {
    left: 0;
    z-index: 1001;
    border-left: 0;
  }
  .main-table-plans-wrapper     th {
    background-clip: padding-box;
  }
  .main-table-plans-wrapper      {
    z-index: 10;
    position: relative;
  }
  .main-table-plans-wrapper      th {
    position: sticky;
    left: 0;
  }

  @media only screen and (max-width:998px){
    .carousel-heading{
        font-size:28px;
        line-height:32px;
    }
}


      @media only screen and (min-width:998px){
          .main-table th,
          .main-table-plans-wrapper th{
              width:46%;
          }
          .main-table td,
          .main-table-plans-wrapper td{
            width:18%;
        }
      }

      @media only screen and(max-width:600px){
      
    }



   @media screen and (min-width: 768px) {
       .desktop-table{
           display: table;
       }
       .mobile-table{
           display: none;
       }
   }

      @media screen and (max-width: 768px) {

        .main-table-plans-wrapper td {
            text-align: center;
            background: #221e54;
            color: white;
            border-color: #312c6e;
        }
        .main-table-plans-wrapper td h6 {
color: white;
        }
        .select-payment-interval-wrapper{
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        .select-payment-interval-wrapper div:first-child .form-check{
           border: none !important;
        }
        .table-wrapper-section{
            overflow-x:scroll;
        }
        .desktop-table{
            display: none;
        }
        .mobile-table{
            display: table;
        }

        .main-table-plans-wrapper.header-plans-wrapper{
            position: sticky;
            top: 55px;
            z-index: 999;
            background: white;
            box-shadow: 0px 3px 5px rgb(0,0,0,.1);
            
        }
        .main-table-plans-wrapper .header-button {
            font-size: 12px;
            padding: 6px 10px;
        }
      }


      
.table-row {border: 1px solid #e9ecef;border-top: none;}


.table-row td, .table-row th {
    width: 100%;
    display: block;
    border: none;
    min-width: auto !important;
}
.table-row td{
    font-size:12px;
}

.table-row .col-12 {
    border-bottom: 1px solid #e9ecef;
    padding: 0px 5px !important;
}

.col-11.mx-auto.px-0 {
    border-bottom: 1px solid #e9ecef;
}

.table-row .col-4:after {
    content: "";
    height: 20px;
    width: 1px;
    background: #e9ecef;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

.table-row .col-4:last-child:after {
   display: none;
}


@media only screen and (min-width:768px) and (max-width:998px){
    .plan-text {
        font-size:.85rem;
    }
}
@media only screen and (max-width:600px){
    .carousel-heading{
        font-size: 22px;
        line-height: 22px;
    }
    .carousel-name-position {
        font-size:16px;
        line-height: 22px;
    }
  }
@media only screen and (max-width:340px){
  .vendor-main-desc{
      font-size:30px;
  }
  .zig-zag-heading{
      font-size:18px;
  }
  .table-row td {
      font-size:10px;
  }
}


.tooltip-mobile{
    position: absolute;
    right: 0px;
}
.table-row th {
    padding-right:15px;
}

.integration-partner-wrapper {
    background: white;
      box-shadow: 0px 0px 20px rgb(0 0 0 / 6%);
      padding: 0;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
     
  }
  .integration-partner-wrapper:hover {
    box-shadow: 0px 0px 20px rgb(0 0 0 / 10%);
  }
  .arrow-btn-partners {
      position:absolute;
      bottom:0px;
      right:0px;
      border-bottom-left-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
      border-top-right-radius: 0px !important;
  }